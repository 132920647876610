/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  font: inherit;
  font-family: 'Roboto', sans-serif;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
	height: 100%;
}
body {
	line-height: 1;
	height: 100%;
}
#root {
	height: 100%;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  background-color: #f5f5f5;
}

h1 {
  font-size: 24px;
  font-weight: lighter;
}

#calibrate {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 40px;
  margin-bottom: 40px;
  z-index: 6;
}
.card {
    width: 100%;
    max-width: 400px;
    min-width: 290px;
    height: 300px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin: 0px 15px 15px 0px;
}

.card > ul {
    display: flex;
    flex-direction: row;
    height: 50px;
    border-bottom: 1px solid #f5f5f5;
}

.card_content_container {
    margin: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px - 50px);
    display: block;
}

.card:last-of-type {
    margin-bottom: 25px;
}

.card > ul li {
    float: left;
    height: 50px;
    line-height: 50px;
    background-color: #f0f0f0;
}

.card > ul li:hover {
    cursor: pointer;
    background-color: #eaeaea;
}

.card > ul li:active, .card > ul li.active:active {
    background-color: #dedede;
}

.card > ul li:first-child {
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    color: #252525;
    display: flex;
    justify-content: space-between;
}

.card > ul li:first-child > *:not(span) {
    position: relative;
    top: 12px;
}

.card > ul li.card_code_tab {
    width: 100px;
    text-align: center;
    border-left: 1px solid #f5f5f5;
}

.card > ul li.active {
    background-color: #eaeaea;
}

.card_standard_feature_view > button {
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin: 70px auto 60px auto;
}

.card_standard_feature_view ul {
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
}

.card_standard_feature_view li {
    font-size: 16px;
    color: black;
    width: 200px;
    line-height: 40px;
    margin: 0px auto;
    text-align: center;
    background-color: #f0f0f0;
}

.card_standard_code_view {
    width: 100%;
}

.loading_icon_outer {
    width: 100%;
    height: 100%;
}

@keyframes loading_icon {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-webkit-keyframes loading_icon {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.loading_icon {
    position: relative;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}

.loading_icon div, .loading_icon div:after {
    position: absolute;
    width: 160px;
    height: 160px;
    border: 20px solid #93dbe9;
    border-top-color: transparent;
    border-radius: 50%;
}

.loading_icon div {
    -webkit-animation: loading_icon 1.5s linear infinite;
    animation: loading_icon 1.5s linear infinite;
    top: 100px;
    left: 100px;
}

.loading_icon div:after {
    transform: rotate(90deg);
}

.loading_icon {
    width: 40px !important;
    height: 40px !important;
    transform: translate(-20px, -20px) scale(0.2) translate(20px, 20px);
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
  }
  
.switch input {display:none;}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 6px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #009cde;
}

input:focus + .slider {
    box-shadow: 0 0 1px #009cde;
}

input:checked + .slider:before {
    transform: translateX(20px);
}

.input {
    display: flex;
    flex-direction: column;
    height: 55px;
}

.input > input {
    display: block;
    height: 30px;
    outline: none;
    background-color: #f0f0f0;
    border: none;
    padding: 5px 10px;
    font-size: 15px;
}

.input > span {
    position: relative;
    top: 3px;
    font-size: 11px;
    opacity: 0.7;
    margin-left: auto;
    right: 3px;
}

.input_small, input_small input {
    width: 100px;
}

.input_medium, input_medium input {
    width: 150px;
}

.input_large, input_large input {
    width: 200px;
}

.loading_dashboard {
    height: 100%;
    position: relative;
    left: -7.5px;
    top: -7.5px;
}

.loading_dashboard > div {
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@media (max-width: 340px) {
    .card > ul li:first-child span {
        font-size: 15px;
    }
}

.button_blue, .button_red, .button_green {
    color: white;
    outline: none;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    text-align: center;
    display: block;
    font: inherit;
    height: 100%;
}

.button_blue {
    background-color: #009cde;
}

.button_blue:hover {
    background-color: #008cc7;
}
  
.button_blue:active, .button_blue:disabled {
    background-color: #007cb1;
}

.button_red {
    background-color: #DD6461;
}

.button_red:hover {
    background-color: #DB5C58;
}
  
.button_red:active, .button_red:disabled {
    background-color: #d9534f;
}

.button_green {
    background-color: #6CBF6C;
}

.button_green:hover {
    background-color: #64BC64;
}
  
.button_green:active, .button_green:disabled {
    background-color: #5cb85c;
}

.card_color_view {
    height: 100%;
    display: block;
  }
  
  .card_color_view div {
    display: inline-block;
    width: 50%;
    height: 110px;
    margin: 40px 0px 0px 25%;
  }
  
  
  .card_color_view p {
    display: block;
    text-align: center;
    margin-top: 15px;
  }
  
  .card_chart_view {
    height: 80%
  }
  
  .card_chart_view p + * {
    margin-top: 30px;
  }

  .card_metrics_feature_view {
    height: 100%;
  }

  #metrics div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  #metrics div:last-child {
    margin-bottom: 0px;
  }
#configuration {
  color: dimgray;
  max-width: 380px;
  min-width: 240px;
  height: 450px !important;
  background-color: #fff;
  display: flex;
  flex-direction: column !important;
  padding-left: 1em;
  margin-right: 15px;
}

.full_width {
  width: 100%;
}

#configuration #firmwareLabel {
  height: 1.5em;
  margin-top: 0.7em;
}

#configuration h3 {
  color: black;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}

#configuration .component {
  width: 100%;
  height: 2em;
  cursor: pointer;
  margin-top: 0.7em;
  display: flex;
  align-items: center;
}

#configuration img {
  opacity: 0.65;
  width: 18px;
  height: 18px;
  margin: 0px 15px;
  top: 4px;
}

.modal .header {
  font-size: 18px;
  text-align: center;
  padding: 5px;
  font-weight: 500;
  color: black;
}
.modal > .content {
  padding: 10px 5px;
}

.modal input, select {
  position: relative;
  outline: none;
  box-shadow: none;
  padding: 0;
  width: 100%;
  max-width: 100%;
  background: transparent;
  border: none;
  color: #212121;
  -webkit-appearance: none;
  text-align: inherit;
  vertical-align: bottom;
  font-family: 'Roboto', 'Noto', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.modal .actions button {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 0;
  font-size: inherit;
  text-transform: inherit;
  font-weight: inherit;
  outline: none;
  cursor: pointer;
  color: black;
  padding: 10px 5px;
  text-align: center;
  position: relative;
  border-radius: none;
}

.modal .actions button:hover {
  background-color: rgba(0, 0, 0, 0.15)
}

.modal .underline {
  border-bottom: 1px solid #737373; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  max-height: 2px;
  min-height: 2px;
}

.modal input:focus + .underline {
  border-bottom: 2px solid #3f51b5;
}

.modal .actions {
  padding: 10px 5px 0px 5px;
  display: flex;
  justify-content: space-evenly;
}

.modal .info {
  font-size: 12px;
  padding-top: 2em;
}

.popup-content {
  overflow: auto;
  max-height: 610px;
  max-width: 300px;
  min-width: 275px;
}

.popup {
  background-color: #fff;
  padding: 20px;
  position: fixed;
  overflow: auto;
  max-height: 610px;
  max-width: 300px;
  min-width: 235px;
  z-index: 1300;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-height: 670px) {
  .popup {
    max-height: 90%;
  }
}

.popup_buttons {
  height: 40px;
  display: flex;
  flex-direction: row;
  width: 200px;
  margin: 20px auto 0px auto;
}

.popup_buttons button {
  width: 50%;
  line-height: 40px;
}

.popup_container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0,0,0,0.4);
  display: inline-block;
  z-index: 1200;
}
#trigger {
  position: fixed;
  /*display: block;*/
  right: 0;
  bottom: 0;
  margin-right: 40px;
  margin-bottom: 40px;
  z-index: 6;
}
